import React from 'react'
import PropTypes from 'prop-types'

import bumpcollab from '../images/bump-collab.jpg'

class Main extends React.Component {
  render() {

    let close = <div className="close" onClick={() => {this.props.onCloseArticle()}}></div>

    return (
      <div ref={this.props.setWrapperRef} id="main" style={this.props.timeout ? {display: 'flex'} : {display: 'none'}}>

        <article id="work" className={`${this.props.article === 'work' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Solutions</h2>
          <p>Our primary goal with our solutions is to give you the confidence that you need in your technology, so that you can focus on your business domain. We focus our transformational services in these core areas:</p>

          <h3>Modern Web</h3>

          <p>Whether it's React, Angular, or Vue.js, we can help you deliver value out of your web presence in a hurry. In our projects, we prefer cloud-native implementations that scale from day 1, and benefit from a variety of open source tools and standards.</p>
          <p>Modernize your Wordpress site with a responsive, mobile-first front-end, powered by a secure, serverless API-gateway for all of your dynamic content needs. We use a variety of providers, keeping in mind any existing best practices within your organization.</p>

          <ul>
            <li>Modern <a href="https://jamstack.org/" target="_blank">JAM-based</a> front-end stacks (React, Vue.js</li>
            <li>Scaleable micro-services</li>
            <li>Integration services with other API's</li>
          </ul>

          <p />

          <h3>DevOps</h3>

          <p>Having solutions which are easy to deploy and maintain is not accomplished in a day, and it is certainly not easy to preserve over the months and years as software changes. No matter where your organization is on its DevOps journey, we can help you identify areas to make incremental investments that will continue to give back time and focus to your teams.</p>
          <p>We can come alongside your team(s) and leadership, helping to journey towards both cultural and technology challenges. We apply industry-standard best practices on the technology front, helping automate builds, testing, and deployment.</p>

          <ul>
            <li>Classic VM-based provisioning</li>
            <li>Docker Containers</li>
            <li>CI pipelines for build, test, deploy (Jenkins, etc)</li>
            <li>Cloud-native, serverless scaling (Google, AWS, etc)</li>
          </ul>

          <p />

          {close}
        </article>

        <article id="about" className={`${this.props.article === 'about' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">About</h2>
          <span className="image main"><img src={bumpcollab} alt="" /></span>
          <p>We are a small, but growing firm, specializing in technology solutions and training. Whether you need to "rev up" skills in your current workforce, embed an expert, or scale your development muscle with our team, we want to help you!</p>
          <p>We are establishing partnerships with a curated short-list of offshore firms, who can help us deliver for our clients. If you would like to discuss possibilities for your company, get in touch on our contact page.</p>
          <p />
          {close}
        </article>

        <article id="contact" className={`${this.props.article === 'contact' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Contact</h2>



          <form method="post" action="https://us-central1-skillrev-dev.cloudfunctions.net/contactMe">
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li><input type="submit" value="Send Message" className="special" /></li>
              <li><input type="reset" value="Reset" /></li>
            </ul>
          </form>
          <ul className="icons">
            <li><a href="https://www.linkedin.com/in/jeff-reed-1631875/" target="_blank" className="icon fa-linkedin"><span className="label">Get in touch on LinkedIn</span></a></li>
          </ul>
          {close}
        </article>

      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main